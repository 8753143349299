// @flow
import React, { useState, type Node, useContext, useEffect } from 'react';

import { Button, Spin, Table } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useGetAlertsQuery, useUpdateAlertMutation } from '../../../stores/alertsSlice';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { ALERTS_STATUS, DEFAULT_PAGE_SIZE } from './constants';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;
  padding: 16px;

  .ant-table-pagination.ant-pagination {
    justify-content: right;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title,
  .ant-statistic-content-suffix {
    font-size: 13px;
  }
`;

const TableContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
`;

type propsT = {
  account: accountT,
};

export const AlertsTable = ({ account }: propsT): Node => {
  const { messages } = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: alerts,
    error,
    isLoading: alertsLoading,
  } = useGetAlertsQuery({
    page: currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    status: `${ALERTS_STATUS.UNREAD},${ALERTS_STATUS.READ}`,
  });
  const [updateAlert] = useUpdateAlertMutation();

  const updateAlertStatus = (id, status) => {
    updateAlert({ id, status });
  };

  const columns = [
    {
      key: 'alertName',
      title: messages.locales.columns.alertName,
      width: 200,
      render: (v) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div>{v.title}</div>
            <div>{v.message}</div>
          </div>
        );
      },
    },
    {
      key: 'accountName',
      title: messages.locales.columns.accountName,
      dataIndex: 'accountName',
      width: 200,
    },
    {
      key: 'resourceName',
      title: messages.locales.columns.resourceName,
      dataIndex: 'resourceType',
      width: 200,
    },
    {
      key: 'status',
      title: messages.locales.columns.status,
      dataIndex: 'status',
      width: 150,
    },
    {
      title: messages.locales.columns.actions,
      width: 300,
      fixed: 'right',
      render: (v) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <Button type="primary" ghost onClick={() => updateAlertStatus(v.id, ALERTS_STATUS.READ)}>
              Mark as Read
            </Button>
            <Button type="danger" ghos onClick={() => updateAlertStatus(v.id, ALERTS_STATUS.DISMISSED)}>
              Dismiss
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Style>
      <TableContainer>
        {alertsLoading ? (
          <Spinner size={SpinnerSize.STANDARD} />
        ) : (
          <Table
            style={{ cursor: 'pointer' }}
            rowKey="id"
            bordered
            tableLayout="fixed"
            dataSource={alerts?.items}
            columns={columns}
            scroll={{ x: 1300 }}
            sticky
            pagination={{
              defaultPageSize: DEFAULT_PAGE_SIZE,
              onChange: (page) => setCurrentPage(page),
              total: alerts?.pagination?.total,
            }}
          />
        )}
      </TableContainer>
    </Style>
  );
};
